import React from 'react';
import * as Ink from '@carta/ink';

const UsagePropsTable = ({ props }: Record<string, string>) => {
  return (
    <>
      {props.length === 0 && (
        <Ink.Paragraph>
          <i>This instance has no props in use</i>
        </Ink.Paragraph>
      )}

      {props.length > 0 && (
        <Ink.NewTable>
          <Ink.NewTable.Body>
            {props.map(([key, value]) => {
              const cleanValue = value === true ? 'true' : value;

              return (
                <Ink.NewTable.Row {...{ key }}>
                  <Ink.NewTable.Cell preset="key">{key}</Ink.NewTable.Cell>
                  <Ink.NewTable.Cell>{cleanValue}</Ink.NewTable.Cell>
                </Ink.NewTable.Row>
              );
            })}
          </Ink.NewTable.Body>
        </Ink.NewTable>
      )}
    </>
  );
};

export default UsagePropsTable;
