import React, { useEffect, useState } from 'react';
import { navigate, PageProps, withPrefix } from 'gatsby';
import { parseLocation } from '../../../helpers/usage';
import Name from '../../../components/Usage/Name';
import Repo from '../../../components/Usage/Repo';
import Path from '../../../components/Usage/Path';
import Location from '../../../components/Usage/Location';
import NotFound from '../../../components/Usage/NotFound';

const UsageSplatPage = ({ location }: Pick<PageProps, 'location'>) => {
  const [usageData, setUsageData] = useState({});

  useEffect(() => {
    fetch(withPrefix('datasets/usage.json'))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);
      });
  }, []);

  // Check to see if this is a CircleCI test URL
  const testUrlRegex = new RegExp(/(?<=artifacts\/0).*/);
  const testPathname = testUrlRegex.exec(location.pathname)?.[0];

  const { usage, components, name, repo, path, location: codeLocation } = {
    ...parseLocation(testPathname ?? location.pathname),
  };

  console.log({ usage, components, name, repo, path, location });

  const usageExists = usage === 'usage';
  const componentsExists = components === 'components';
  const nameExists = !!(name && usageData?.[name]);
  const repoExists = !!(name && repo && usageData?.[name]?.[repo]);
  const pathExists = !!(name && repo && path && usageData?.[name]?.[repo]?.[path]);
  const locationExists = !!(name && repo && path && codeLocation && usageData?.[name]?.[repo]?.[path]?.[codeLocation]);

  const isRootRoute = location.pathname.split('/').pop() === 'index.html';
  const usageAndComponentsExist = usageExists && componentsExists;
  const isNameRoute = !!(usageAndComponentsExist && nameExists && !repoExists && !pathExists && !locationExists);
  const isRepoRoute = !!(usageAndComponentsExist && nameExists && repoExists && !pathExists && !locationExists);
  const isPathRoute = !!(usageAndComponentsExist && nameExists && repoExists && pathExists && !locationExists);
  const isLocationRoute = !!(usageAndComponentsExist && nameExists && repoExists && pathExists && locationExists);

  const root = '/usage/components';

  if (isRootRoute) return navigate(root);
  if (isNameRoute) return <Name location={{ pathname: `${root}/${name}`, search: location.search }} />;
  if (isRepoRoute) return <Repo location={{ pathname: `${root}/${name}/${repo}` }} />;
  if (isPathRoute) return <Path location={{ pathname: `${root}/${name}/${repo}/${path}` }} />;
  if (isLocationRoute) return <Location location={{ pathname: `${root}/${name}/${repo}/${path}/${codeLocation}` }} />;

  return <NotFound />;
};

export default UsageSplatPage;
