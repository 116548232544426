import React, { useEffect, useState } from 'react';
import { PageProps, withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { checkBannerSpecificProps, getComponentNameFromSlug, getUsageDataGenerationDate } from '../../helpers/usage';
import Content from '../Content/Content';
import ErrorBoundary from '../ErrorBoundary';
import Layout from '../Layout';
import PreviewCard from '../Common/PreviewCard';
import { COMPONENT_SLUGS_TO_NAMES } from '../../constants';
import PropsTable from './PropsTable';
import Top from './Top';

const UsagePathPage = ({ location: pageLocation }: Pick<PageProps, 'location'>) => {
  const { pathname } = pageLocation;

  const parts: string[] = pathname.split('/') ?? '';
  const name: typeof COMPONENT_SLUGS_TO_NAMES = getComponentNameFromSlug(parts.slice(3, 4).join('')) ?? '';
  const repo: string = parts.slice(4, 5).join('') ?? '';
  const path: string = parts.slice(5, -1).join('/') ?? '';
  const location: string = parts.pop() ?? '';

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usageData, setUsageData] = useState({});
  const [revisions, setRevisions] = useState({});

  useEffect(() => {
    fetch(withPrefix(`datasets/usage.json`))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);
        setDate(getUsageDataGenerationDate(data.date));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(withPrefix('datasets/revisions.json'))
      .then(response => response.json())
      .then(data => {
        setRevisions(data);
      });
  }, []);

  const propsObject = {};
  const sourceLink = `https://github.com/carta/${repo}/blob/${revisions[repo]}/${path}#${location}`;
  const file = path.split('/')[path.split('/').length - 1];

  // @ts-ignore TODO: Clean up this type so values can be used as indices
  let Component;
  if (name.includes('.')) {
    const [namespace, ...componentName] = name.split('.');
    Component = Ink[namespace][componentName.join('.')];
  } else {
    Component = Ink[name];
  }

  (usageData?.[name]?.[repo]?.[path]?.[location]?.props || []).forEach(([key, value]) => {
    if (
      // Banner-specific checks
      checkBannerSpecificProps({ name, key, value })

      // TODO: Add other component-specific checks
    ) {
      propsObject[key] = value;
    }
  });

  return (
    <Layout pageTitle={`Usage / Components / ${name} / ${repo} / ${path} / ${location}`}>
      <Top
        breadcrumbLinks={[
          { children: 'Usage', to: '/usage' },
          { children: 'Components', to: '/usage/components' },
          { children: name, to: `/usage/components/${name}` },
          { children: repo, to: `/usage/components/${name}/${repo}` },
          { children: file, to: `/usage/components/${name}/${repo}/${path}` },
        ]}
        componentName={name}
        subtitle={`${name} usage data in ${file} at ${location} within ${repo} as of ${date}`}
        title={location}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/components/Usage/Location.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading2>Visual of the component with its props in use</Ink.Heading2>

              <Ink.VStack key={sourceLink} spacing="small">
                <Ink.Block trim>
                  {Component && (
                    <ErrorBoundary>
                      <Component {...propsObject} children={`{{ ${name} }}`} />
                    </ErrorBoundary>
                  )}
                </Ink.Block>

                <PropsTable props={usageData[name][repo][path][location].props} />

                <Ink.HStack>
                  <Ink.Anchor href={sourceLink} preset="new-tab" rel="noreferrer noopener" target="_blank">
                    View source
                  </Ink.Anchor>
                </Ink.HStack>
              </Ink.VStack>
            </Ink.Box>

            <Ink.Box top="xlarge">
              <Ink.Heading2>Resources</Ink.Heading2>

              <Ink.Tiles columns={[1, 1, 2, 3]}>
                <PreviewCard
                  key={`${name}${sourceLink}`}
                  title={name}
                  text={`ink.carta.com/components/${name}`}
                  actionLabel={`View ${name} documentation`}
                  actionHref={`/components/${name}`}
                  previewWithCheckerboard
                />
              </Ink.Tiles>
            </Ink.Box>
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsagePathPage;
