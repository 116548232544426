import React, { useEffect, useState } from 'react';
import { PageProps, withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { checkBannerSpecificProps, getComponentNameFromSlug, getUsageDataGenerationDate } from '../../helpers/usage';
import Content from '../Content/Content';
import ErrorBoundary from '../ErrorBoundary';
import Layout from '../Layout';
import { COMPONENT_SLUGS_TO_NAMES } from '../../constants';
import PropsTable from './PropsTable';
import Top from './Top';
import StyledLink from '../Common/StyledLink';

const UsageRepoPage = ({ location }: Pick<PageProps, 'location'>) => {
  const { pathname } = location;
  const repo: keyof typeof COMPONENT_SLUGS_TO_NAMES = pathname.split('/').pop() ?? '';

  const name: typeof COMPONENT_SLUGS_TO_NAMES =
    getComponentNameFromSlug(pathname.split('/').slice(3, 4).join('')) ?? '';

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usageData, setUsageData] = useState({});
  const [revisions, setRevisions] = useState({});

  useEffect(() => {
    fetch(withPrefix(`datasets/usage.json`))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);
        setDate(getUsageDataGenerationDate(data.date));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(withPrefix('datasets/revisions.json'))
      .then(response => response.json())
      .then(data => {
        setRevisions(data);
      });
  }, []);

  return (
    <Layout pageTitle={`Usage / Components / ${name} / ${repo}`}>
      <Top
        breadcrumbLinks={[
          { children: 'Usage', to: '/usage' },
          { children: 'Components', to: '/usage/components' },
          { children: name, to: `/usage/components/${name}` },
        ]}
        componentName={name}
        subtitle={`${name} usage data in ${repo} as of ${date}`}
        title={repo}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/components/Usage/Repo.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading1>
                {new Intl.NumberFormat().format(usageData[name][repo].count ?? 0)}{' '}
                {usageData[name][repo].count === 1 ? 'instance' : 'instances'} of {name} found in {repo}
              </Ink.Heading1>

              <Ink.Box top="large">
                <Ink.HStack>
                  <Ink.Box minWidth={['none', '600px']}>
                    <Ink.NewTable>
                      <Ink.NewTable.Head>
                        <Ink.NewTable.Row>
                          <Ink.NewTable.HeadCell>Path</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell align="right">Count in use</Ink.NewTable.HeadCell>
                        </Ink.NewTable.Row>
                      </Ink.NewTable.Head>

                      <Ink.NewTable.Body>
                        {Object.keys(usageData[name][repo])
                          .filter(p => p !== 'count')
                          .sort((p1, p2) => usageData[name][repo][p2].count - usageData[name][repo][p1].count)
                          .map(p => {
                            return (
                              <Ink.NewTable.Row key={p}>
                                <Ink.NewTable.Cell>
                                  <StyledLink to={`/usage/components/${name}/${repo}/${p}`}>{p}</StyledLink>
                                </Ink.NewTable.Cell>

                                <Ink.NewTable.Cell align="right">{usageData[name][repo][p].count}</Ink.NewTable.Cell>
                              </Ink.NewTable.Row>
                            );
                          })}
                      </Ink.NewTable.Body>
                    </Ink.NewTable>
                  </Ink.Box>
                </Ink.HStack>
              </Ink.Box>
            </Ink.Box>

            <Ink.Box top="xlarge">
              <Ink.Heading2>Visuals of the components with their props in use</Ink.Heading2>

              {Object.keys(usageData[name][repo])
                .filter(p => p !== 'count')
                .sort((p1, p2) => usageData[name][repo][p2].count - usageData[name][repo][p1].count)
                .map(p => {
                  return (
                    <>
                      <Ink.Heading2 id={`${repo}/${p}`}>
                        <StyledLink to={`/usage/components/${name}/${repo}/${p}`}>{p}</StyledLink>
                      </Ink.Heading2>

                      {Object.keys(usageData[name][repo][p])
                        .filter(l => l !== 'count')
                        .map(l => {
                          const propsObject = {};
                          const sourceLink = `https://github.com/carta/${repo}/blob/${revisions[repo]}/${p}#${l}`;

                          // @ts-ignore TODO: Clean up this type so values can be used as indices
                          let Component;
                          if (name.includes('.')) {
                            const [namespace, ...componentName] = name.split('.');
                            Component = Ink[namespace][componentName.join('.')];
                          } else {
                            Component = Ink[name];
                          }

                          usageData[name]?.[repo][p][l].props.forEach(([key, value]) => {
                            if (
                              // Banner-specific checks
                              checkBannerSpecificProps({ name, key, value })

                              // TODO: Add other component-specific checks
                            ) {
                              propsObject[key] = value;
                            }
                          });

                          return (
                            <Ink.Box bottom="xlarge">
                              <Ink.VStack key={sourceLink} spacing="small">
                                <Ink.Block trim>
                                  {Component && (
                                    <ErrorBoundary>
                                      <Component {...propsObject} children={`{{ ${name} }}`} />
                                    </ErrorBoundary>
                                  )}
                                </Ink.Block>

                                <PropsTable props={usageData[name][repo][p][l].props} />

                                <Ink.HStack>
                                  <StyledLink to={`/usage/components/${name}/${repo}/${p}/${l}`}>
                                    View component usage
                                  </StyledLink>

                                  <Ink.Anchor
                                    href={sourceLink}
                                    preset="new-tab"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                  >
                                    View source
                                  </Ink.Anchor>
                                </Ink.HStack>
                              </Ink.VStack>
                            </Ink.Box>
                          );
                        })}
                    </>
                  );
                })}
            </Ink.Box>
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsageRepoPage;
