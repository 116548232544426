import React, { useEffect, useState } from 'react';
import { PageProps, withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { getComponentNameFromSlug, getUsageDataGenerationDate } from '../../helpers/usage';
import Content from '../Content/Content';
import Layout from '../Layout';
import { COMPONENT_SLUGS_TO_NAMES } from '../../constants';
import Top from './Top';
import StyledLink from '../Common/StyledLink';
import PropCountTable from './PropCountTable';
import InstanceList from './InstanceList';

export interface UsageComponent {
  children: string;
  file: string;

  /* eslint-disable camelcase */
  item_number: string;
  line_end: string;
  line_start: string;
  /* eslint-enable camelcase */

  name: string;
  props: [string, string | boolean][];
  repo: string;
}

const UsageNamePage = ({ location }: Pick<PageProps, 'location'>) => {
  const { pathname, search } = location;
  const slug: keyof typeof COMPONENT_SLUGS_TO_NAMES = pathname.split('/').pop() ?? '';
  const name = getComponentNameFromSlug(slug);
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usageData, setUsageData] = useState({});
  const [revisions, setRevisions] = useState({});
  const propParam = new URLSearchParams(search).get('prop') ?? '';
  const valueParam = new URLSearchParams(search).get('value') ?? '';
  console.log({ location });
  useEffect(() => {
    fetch(withPrefix(`datasets/usage.json`))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);
        setDate(getUsageDataGenerationDate(data.date));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(withPrefix('datasets/revisions.json'))
      .then(response => response.json())
      .then(data => {
        setRevisions(data);
      });
  }, []);

  return (
    <Layout pageTitle={`Usage / Components / ${name}`}>
      <Top
        breadcrumbLinks={[
          { children: 'Usage', to: '/usage' },
          { children: 'Components', to: '/usage/components' },
        ]}
        componentName={name}
        subtitle={`${name} usage data as of ${date}`}
        title={name}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/components/Usage/Name.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading1>
                {new Intl.NumberFormat().format(usageData[name].count ?? 0)}{' '}
                {usageData[name].count === 1 ? 'instance' : 'instances'} found in the following repositories
              </Ink.Heading1>

              <Ink.Box top="large">
                <Ink.HStack>
                  <Ink.Box minWidth={['none', '600px']}>
                    <Ink.NewTable>
                      <Ink.NewTable.Head>
                        <Ink.NewTable.Row>
                          <Ink.NewTable.HeadCell>Repo</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell align="right">Count in use</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell> </Ink.NewTable.HeadCell>
                        </Ink.NewTable.Row>
                      </Ink.NewTable.Head>

                      <Ink.NewTable.Body>
                        {Object.keys(usageData[name])
                          .filter(r => r !== 'count')
                          .sort((r1, r2) => usageData[name][r2].count - usageData[name][r1].count)
                          .map(r => {
                            return (
                              <Ink.NewTable.Row key={r}>
                                <Ink.NewTable.Cell>
                                  <StyledLink to={`/usage/components/${name}/${r}`}>{r}</StyledLink>
                                </Ink.NewTable.Cell>

                                <Ink.NewTable.Cell align="right">{usageData[name][r].count}</Ink.NewTable.Cell>
                                <Ink.NewTable.Cell width="200px">
                                  <Ink.Progress data={usageData[name][r].count} maximum={usageData[name].count} trim />
                                </Ink.NewTable.Cell>
                              </Ink.NewTable.Row>
                            );
                          })}
                      </Ink.NewTable.Body>
                    </Ink.NewTable>
                  </Ink.Box>
                </Ink.HStack>
              </Ink.Box>
            </Ink.Box>

            {/* By prop */}
            <PropCountTable component={usageData[name]} propName={propParam} name={name} valueName={valueParam} />

            {!name.includes('.') && (
              <InstanceList
                name={name}
                instances={usageData[name]}
                revisions={revisions}
                prop={propParam}
                value={valueParam}
              />
            )}
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsageNamePage;
