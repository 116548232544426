import React from 'react';
import * as Ink from '@carta/ink';
import Content from '../Content/Content';
import Header from '../Header/Header';
import Layout from '../Layout';
import StyledLink from '../Common/StyledLink';

const UsageNotFoundPage = () => {
  return (
    <Layout pageTitle="Usage / Components / Not found">
      <Ink.Box bottom="medium">
        <StyledLink to="/usage">Usage</StyledLink>
        {' / '}
        <StyledLink to="/usage/components">Components</StyledLink>
      </Ink.Box>

      <Header
        title={
          <Ink.HStack alignY="center" spacing="small">
            Not found
            <Ink.Box display="flex">
              <Ink.Bubble type="purple">Preview</Ink.Bubble>
            </Ink.Box>
          </Ink.HStack>
        }
        subtitle="This component does not exist"
        actions={[
          {
            label: 'View source',
            type: 'btn',
            href: 'https://github.com/carta/ink/docs/src/components/Usage/NotFound.tsx',
          },
          {
            label: 'Contribute',
            type: 'primary',
            href: 'https://github.com/carta/ink/blob/master/CONTRIBUTING.md',
          },
        ]}
      />

      <Content>
        <Ink.VStack>
          <Ink.Box top="xlarge">
            <Ink.Heading1>Component name, repo, path, or location was not found</Ink.Heading1>
            <Ink.Paragraph>
              <i>
                Check the URL or see <Ink.Anchor href="/usage/components">the list of all components in use</Ink.Anchor>
              </i>
            </Ink.Paragraph>
          </Ink.Box>
        </Ink.VStack>
      </Content>
    </Layout>
  );
};

export default UsageNotFoundPage;
