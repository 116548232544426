import React, { useEffect, useState } from 'react';
import { PageProps, withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { checkBannerSpecificProps, getComponentNameFromSlug, getUsageDataGenerationDate } from '../../helpers/usage';
import Content from '../Content/Content';
import ErrorBoundary from '../ErrorBoundary';
import Layout from '../Layout';
import { COMPONENT_SLUGS_TO_NAMES } from '../../constants';
import PropsTable from './PropsTable';
import Top from './Top';
import StyledLink from '../Common/StyledLink';

const UsagePathPage = ({ location }: Pick<PageProps, 'location'>) => {
  const { pathname } = location;

  const parts: string[] = pathname.split('/') ?? '';
  const name: typeof COMPONENT_SLUGS_TO_NAMES = getComponentNameFromSlug(parts.slice(3, 4).join('')) ?? '';
  const repo: string = parts.slice(4, 5).join('') ?? '';
  const path: string = parts.slice(5).join('/') ?? '';

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usageData, setUsageData] = useState({});
  const [revisions, setRevisions] = useState({});

  useEffect(() => {
    fetch(withPrefix(`datasets/usage.json`))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);
        setDate(getUsageDataGenerationDate(data.date));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(withPrefix('datasets/revisions.json'))
      .then(response => response.json())
      .then(data => {
        setRevisions(data);
      });
  }, []);

  const file = path.split('/')[path.split('/').length - 1];

  return (
    <Layout pageTitle={`Usage / Components / ${name} / ${repo} / ${file}`}>
      <Top
        breadcrumbLinks={[
          { children: 'Usage', to: '/usage' },
          { children: 'Components', to: '/usage/components' },
          { children: name, to: `/usage/components/${name}` },
          { children: repo, to: `/usage/components/${name}/${repo}` },
        ]}
        componentName={name}
        subtitle={`${name} usage data in ${file} within ${repo} as of ${date}`}
        title={file}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/components/Usage/Repo.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading1>
                {new Intl.NumberFormat().format(usageData[name][repo][path].count ?? 0)}{' '}
                {usageData[name][repo][path].count === 1 ? 'instance' : 'instances'} found in {file} within {repo}
              </Ink.Heading1>
            </Ink.Box>

            <Ink.Box top="xlarge">
              <Ink.Heading2>Visuals of the components with their props in use</Ink.Heading2>

              {Object.keys(usageData[name][repo][path])
                .filter(l => l !== 'count')
                .sort((l1, l2) => usageData[name][repo][path][l2].count - usageData[name][repo][path][l1].count)
                .map(l => {
                  const propsObject = {};
                  const sourceLink = `https://github.com/carta/${repo}/blob/${revisions[repo]}/${path}#${l}`;

                  // @ts-ignore TODO: Clean up this type so values can be used as indices
                  let Component;
                  if (name.includes('.')) {
                    const [namespace, ...componentName] = name.split('.');
                    Component = Ink[namespace][componentName.join('.')];
                  } else {
                    Component = Ink[name];
                  }

                  usageData[name]?.[repo][path][l].props.forEach(([key, value]) => {
                    if (
                      // Banner-specific checks
                      checkBannerSpecificProps({ name, key, value })

                      // TODO: Add other component-specific checks
                    ) {
                      propsObject[key] = value;
                    }
                  });

                  return (
                    <Ink.Box bottom="xlarge">
                      <Ink.VStack key={sourceLink} spacing="small">
                        <Ink.Block trim>
                          {Component && (
                            <ErrorBoundary>
                              <Component {...propsObject} children={`{{ ${name} }}`} />
                            </ErrorBoundary>
                          )}
                        </Ink.Block>

                        <PropsTable props={usageData[name][repo][path][l].props} />

                        <Ink.HStack>
                          <StyledLink to={`/usage/components/${name}/${repo}/${path}/${l}`}>
                            View component usage
                          </StyledLink>

                          <Ink.Anchor href={sourceLink} preset="new-tab" rel="noreferrer noopener" target="_blank">
                            View source
                          </Ink.Anchor>
                        </Ink.HStack>
                      </Ink.VStack>
                    </Ink.Box>
                  );
                })}
            </Ink.Box>
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsagePathPage;
