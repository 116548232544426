import React, { useEffect } from 'react';
import * as Ink from '@carta/ink';
import { Link, withPrefix } from 'gatsby';
import { checkBannerSpecificProps } from '../../helpers/usage';
import ErrorBoundary from '../ErrorBoundary';
import PropsTable from './PropsTable';

interface propTypes {
  instances: any;
  revisions: any;
  name: string;
  prop?: string;
  value?: string;
}

const getHeadingText = (prop, value) => {
  if (prop !== '') {
    if (value !== '') {
      return `Visuals of the components with "${prop}" defined as "${value}"`;
    }
    return `Visuals of the components with "${prop}" defined`;
  }
  return `Visuals of the components with their props in use`;
};

const InstanceList = ({ instances, name, prop, value, revisions }: propTypes) => {
  return (
    <Ink.Box top="xlarge">
      <Ink.Heading variant="heading-2">{getHeadingText(prop, value)}</Ink.Heading>
      {Object.keys(instances)
        .filter(r => r !== 'count')
        .map(r => (
          <>
            <Ink.Heading variant="heading-2">
              <Link to={`/usage/components/${name}/${r}`}>{r}</Link>
            </Ink.Heading>
            {Object.keys(instances[r])
              .filter(p => p !== 'count')
              .map(p =>
                Object.keys(instances[r][p])
                  .filter(l => {
                    if (l !== 'count') {
                      if (prop !== '') {
                        if (value !== '') {
                          return (
                            instances[r][p][l].props.some(([k, v]) => k === prop) &&
                            instances[r][p][l].props.some(([k, v]) => v === value)
                          );
                        }
                        return instances[r][p][l].props.some(([k, v]) => k === prop);
                      }
                    }
                    return l !== 'count';
                  })
                  .map(l => {
                    const propsObject = {};
                    const sourceLink = `https://github.com/carta/${r}/blob/${revisions[r]}/${p}#${l}`;

                    let Component;
                    if (name.includes('.')) {
                      const [namespace, ...componentName] = name.split('.');
                      Component = Ink[namespace][componentName.join('.')];
                    } else {
                      Component = Ink[name];
                    }

                    instances?.[r][p][l].props.forEach(([key, value]) => {
                      if (
                        // Banner-specific checks
                        checkBannerSpecificProps({ name, key, value })

                        // TODO: Add other component-specific checks
                      ) {
                        propsObject[key] = value;
                      }
                    });
                    return (
                      <Ink.Box id={`${r}/${p}`} bottom="xlarge">
                        <Ink.Heading variant="heading-2">
                          <Link to={`/usage/components/${name}/${r}/${p}`}>{p}</Link>
                        </Ink.Heading>
                        <Ink.VStack key={l} spacing="small">
                          <Ink.Tile trim>
                            {Component && (
                              <ErrorBoundary>
                                <Component {...propsObject} children={`{{ ${name} }}`} />
                              </ErrorBoundary>
                            )}
                          </Ink.Tile>

                          <PropsTable props={instances[r][p][l].props} />

                          <Ink.HStack>
                            <Link to={`/usage/components/${name}/${r}/${p}/${l}`}>View component usage</Link>

                            <Ink.Anchor href={sourceLink} preset="new-tab" rel="noreferrer noopener" target="_blank">
                              View source
                            </Ink.Anchor>
                          </Ink.HStack>
                        </Ink.VStack>
                      </Ink.Box>
                    );
                  }),
              )}
          </>
        ))}
    </Ink.Box>
  );
};

export default InstanceList;
