import React, { useEffect, useState } from 'react';
import * as Ink from '@carta/ink';
import { Link } from 'gatsby';

interface groupedPropType {
  instances: {
    repo: string;
    file: string;
    line: string;
    value: string;
  }[];
  prop: string;
  count: number;
}

interface selectedPropType extends groupedPropType {
  values?: {
    value: string;
    count: number;
  }[];
}

interface propTypes {
  component: any;
  propName?: string;
  valueName?: string;
  name: string;
}

const PropCountTable = ({ component, propName, valueName, name }: propTypes) => {
  const [groupedProps, setGroupedProps] = useState<groupedPropType[]>([]);
  const [selectedProp, setSelectedProp] = useState<selectedPropType | null>(null);
  const progressBarWidth = '200px';

  useEffect(() => {
    // build groupedPropType list
    const list: groupedPropType[] = [];
    Object.keys(component)
      .filter(r => r !== 'count')
      .forEach(r => {
        Object.keys(component[r])
          .filter(f => f !== 'count')
          .forEach(f => {
            Object.keys(component[r][f])
              .filter(l => l !== 'count')
              .forEach(l => {
                Object.keys(component[r][f][l].props).forEach(p => {
                  let found = false;
                  list.forEach(item => {
                    if (item.prop === component[r][f][l].props[p][0]) {
                      const updatedItem = {
                        ...item,
                        count: item.count + 1,
                        instances: [
                          ...item.instances,
                          { repo: r, file: f, line: l, value: component[r][f][l].props[p][1] },
                        ],
                      };
                      found = true;
                      Object.assign(item, updatedItem);
                    }
                  });
                  if (!found) {
                    list.push({
                      instances: [{ repo: r, file: f, line: l, value: component[r][f][l].props[p][1] }],
                      prop: component[r][f][l].props[p][0],
                      count: 1,
                    });
                  }
                });
              });
          });
      });

    // set selected prop with values count
    if (propName) {
      list.forEach(item => {
        if (item.prop === propName) {
          let values: { value: string; count: number }[] = [];
          item.instances.forEach(instance => {
            let found = false;
            values.forEach((v, i) => {
              if (v.value === instance.value) {
                const updatedValue = { ...v, count: v.count + 1 };
                values[i] = updatedValue;
                found = true;
              }
            });
            if (!found) {
              values = [...values, { value: instance.value, count: 1 }];
            }
          });
          values.sort((a, b) => {
            return b.count - a.count;
          });
          setSelectedProp({ ...item, values });
        }
      });
    }

    // sort values by descending count
    list.sort((a, b) => {
      return b.count - a.count;
    });

    setGroupedProps(list);
  }, [propName, component]);

  return (
    <Ink.VStack>
      <Ink.Box top="xlarge">
        <Ink.Heading variant="heading-2">
          Defined props for <em>{name}</em>
        </Ink.Heading>
        <Ink.Box top="large">
          <Ink.HStack>
            <Ink.Box minWidth={['none', '600px']}>
              <Ink.NewTable>
                <Ink.NewTable.Head>
                  <Ink.NewTable.Row>
                    <Ink.NewTable.HeadCell>Prop name</Ink.NewTable.HeadCell>
                    <Ink.NewTable.HeadCell align="right">Count defined</Ink.NewTable.HeadCell>
                    <Ink.NewTable.HeadCell> </Ink.NewTable.HeadCell>
                  </Ink.NewTable.Row>
                </Ink.NewTable.Head>

                <Ink.NewTable.Body>
                  {groupedProps.map(p => (
                    <Ink.NewTable.Row variant={p.prop === propName ? 'feedback-informational' : undefined} key={p.prop}>
                      <Ink.NewTable.Cell>
                        <Link to={`/usage/components/${name}?prop=${p.prop}`}>{p.prop}</Link>
                      </Ink.NewTable.Cell>
                      <Ink.NewTable.Cell align="right">{p.count}</Ink.NewTable.Cell>
                      <Ink.NewTable.Cell width={progressBarWidth}>
                        <Ink.Progress data={p.count} maximum={component.count} trim />
                      </Ink.NewTable.Cell>
                    </Ink.NewTable.Row>
                  ))}
                </Ink.NewTable.Body>
              </Ink.NewTable>
            </Ink.Box>
          </Ink.HStack>
        </Ink.Box>
      </Ink.Box>
      {propName && (
        <Ink.Box top="xlarge">
          <Ink.Heading variant="heading-2" trim>
            {new Intl.NumberFormat().format(selectedProp?.count ?? 0)}{' '}
            {selectedProp?.count === 1 ? 'instance' : 'instances'} where <em>{propName}</em> is defined
          </Ink.Heading>
          <Ink.Text>
            {selectedProp && component.count - selectedProp?.count} instances where <em>{propName}</em> is not defined
          </Ink.Text>
          <Ink.Box top="large">
            <Ink.HStack>
              <Ink.Box minWidth={['none', '600px']}>
                <Ink.NewTable>
                  <Ink.NewTable.Head>
                    <Ink.NewTable.Row>
                      <Ink.NewTable.HeadCell>Value</Ink.NewTable.HeadCell>
                      <Ink.NewTable.HeadCell align="right">Count in use</Ink.NewTable.HeadCell>
                      <Ink.NewTable.HeadCell> </Ink.NewTable.HeadCell>
                    </Ink.NewTable.Row>
                  </Ink.NewTable.Head>

                  <Ink.NewTable.Body>
                    {selectedProp?.values?.map((v, i) => (
                      <Ink.NewTable.Row
                        variant={v.value === valueName ? 'feedback-informational' : undefined}
                        key={v.value}
                      >
                        <Ink.NewTable.Cell>
                          <Link to={`/usage/components/${name}?prop=${selectedProp.prop}&value=${v.value}`}>
                            {v.value}
                          </Link>
                        </Ink.NewTable.Cell>
                        <Ink.NewTable.Cell align="right">{v.count}</Ink.NewTable.Cell>
                        <Ink.NewTable.Cell width={progressBarWidth}>
                          <Ink.Progress data={v.count} maximum={selectedProp.count} trim />
                        </Ink.NewTable.Cell>
                      </Ink.NewTable.Row>
                    ))}
                  </Ink.NewTable.Body>
                </Ink.NewTable>
              </Ink.Box>
            </Ink.HStack>
          </Ink.Box>
        </Ink.Box>
      )}
    </Ink.VStack>
  );
};

export default PropCountTable;
